const __filename = '/middleware/auth.js'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
dayjs.locale('de')

export default defineNuxtRouteMiddleware((to, from) => {
    console.log('to, from', to, from)
    const auth = useAuth();
    try {
        let error = false
        let JWTdata = null
        let eijnsJWT = useCookie('eijns.jwt')?.value

        if (typeof eijnsJWT === 'undefined') {
            error = true
        } else {
            JWTdata = ref(JSON.parse(atob(eijnsJWT?.split('.')[1])))
            const cookieRecord = useRequestHeaders(["cookie"])
        }

        if (error || !JWTdata || dayjs().isAfter(dayjs.unix(JWTdata?.value?.exp))) {
            console.error(`${__filename} ... useAuth() error or no JWTdata on ${window.location.pathname}`)

            if (!['', '/', '/login', '/logout'].includes(window.location.pathname)
                && !window.location.pathname.startsWith('/help')
                && !window.location.pathname.startsWith('/public')
                && !window.location.pathname.startsWith('/verify')
                && !window.location.pathname.startsWith('/confirm')
                && !window.location.pathname.startsWith('/password')
                && !window.location.pathname.startsWith('/register')
                && !window.location.pathname.startsWith('/support')
                && !window.location.pathname.startsWith('/metrics')
                && !window.location.pathname.startsWith('/health')
                && !window.location.pathname.startsWith('/api')
            ) {
                window.location.href = '/logout'
            }
        }

        return {
            eijnsJWT: eijnsJWT,
            JWTdata: JWTdata || {},
        }
    } catch(err) {
        console.error(`${__filename} ... useAuth() catch`)
        return {
            eijnsJWT: 'none',
            JWTdata: {},
        }
    }

    // skip middleware on server
    //if (process.server) return
    // skip middleware on client side entirely
    //if (process.client) return
    // or only skip middleware on initial client load
    //const nuxtApp = useNuxtApp()
    //if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return
})